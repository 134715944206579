import React from 'react';
import classNames from 'classnames';

import { ArrowLeftIcon } from '@radix-ui/react-icons';

import useCustomForm from '../../../../../../hooks/useCustomForm';

import { UserRole } from '../../../../../../model/UserInfo';
import { FieldValueType, SelectField, TextLikeField } from '../../../../../../types/Field';
import { ClientFields, UserFields } from '../../../../../../util/fields/UserFields';
import { BaseForm } from '../../../../../organisms/BaseForm';
import { InputField } from '../../../../../atoms/InputField';
import { StandardSelectFormField } from '../../../../../atoms/StandardSelectFormField';
import { observer } from 'mobx-react-lite';

import { CommonModalFooter } from '../../../common/CommonModalFooter';

import commonModalStyles from '../../../common/CommonModal.module.scss';
import styles from '../LoginOrSignUpModal.module.scss';
import { useSiteStore } from '../../../../../../store/site';
import { useUserStore } from '../../../../../../store/user';
import { AuthStore } from '../authStore';
import { CommonModal } from '../../../common/CommonModal';
import { AuthStepTitle } from '../AuthStepTitle';
import { PasswordField } from '../../../../../atoms/PasswordField';
import { LockIcon } from '../../../../../../images/LockIcon';
import { NameValueDict } from '../../../../../../types/FormTypes';

interface Props {
  auth: AuthStore;
}

export const SignUpAuthStep: React.FC<React.PropsWithChildren<Props>> = observer(({ auth }) => {
  const user = useUserStore();
  const site = useSiteStore();
  const userFields = new UserFields();
  const emailSignupFields = new ClientFields().signupFields;

  const loginFields = user.isAuthenticated() ? [] : userFields.loginFields;
  const onSubmit = (values: NameValueDict<FieldValueType>) => {
    const valuesWithRole = { ...values, role: UserRole.Client };
    return auth.formSubmit(valuesWithRole);
  };

  const {
    fields,
    isLoading,
    handleChange,
    handleFormSubmit: handleSubmit,
    actionAlert,
  } = useCustomForm({
    initialFields: Object.assign(
      {},
      ...loginFields.map((x) => {
        if (x.name === 'email') {
          x.value = auth.userInfo.email;
        }
        return { [x.name]: x };
      }),
      ...emailSignupFields.map((field) => {
        if (user.isAuthenticated()) {
          const userInfo = user.getUserInfo();
          if (userInfo?.[field.name]) {
            field.value = userInfo[field.name];
          }
        }
        return { [field.name]: field };
      })
    ),
    onSubmit,
  });

  return (
    <CommonModal className={styles.modal}>
      <AuthStepTitle auth={auth} label="Sign Up" />

      <div className={classNames(commonModalStyles.modalMainContent, styles.login)}>
        {site.modalProps?.headline || null}

        <BaseForm
          name="signup"
          formId="signup"
          className={styles.emailSignUpForm}
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          actionAlert={actionAlert}
        >
          <InputField
            onChange={handleChange}
            field={fields.firstName as TextLikeField}
            inputProps={{ autoComplete: 'given-name', spellCheck: false }}
          />

          <InputField
            onChange={handleChange}
            field={fields.lastName as TextLikeField}
            inputProps={{ autoComplete: 'family-name', spellCheck: false }}
          />

          <div className="field">
            <StandardSelectFormField
              className={styles.birthYearField}
              field={fields.birthYear as SelectField}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                handleChange(fields.birthYear, event.target.value)
              }
            />
          </div>

          <InputField
            onChange={handleChange}
            field={fields.zipCode as TextLikeField}
            inputProps={{ autoComplete: 'postal-code' }}
          />

          {fields.email && (
            <InputField
              onChange={handleChange}
              field={fields.email as TextLikeField}
              inputProps={{ autoComplete: 'username' }}
            />
          )}

          {fields.password && (
            <PasswordField
              field={fields.password as TextLikeField}
              onChange={handleChange}
              inputProps={{ autoComplete: 'current-password' }}
            />
          )}
        </BaseForm>
      </div>
      <CommonModalFooter className={styles.loginFooter}>
        <button type="button" className="btn" onClick={auth.onDismiss}>
          Cancel
        </button>
        <div className={styles.loginFooterButtonGroup}>
          <button type="button" className="btn secondary" onClick={auth.goBack}>
            <ArrowLeftIcon width={20} height={20} /> Back
          </button>
          <button
            type="submit"
            className={classNames('btn red', isLoading && 'loading')}
            data-testid="btn-continue"
            disabled={isLoading}
            form="signup"
          >
            <LockIcon /> Continue
          </button>
        </div>
      </CommonModalFooter>
    </CommonModal>
  );
});

SignUpAuthStep.displayName = 'SignUpAuthStep';
