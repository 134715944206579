import React, { useCallback, useEffect, useState } from 'react';
import type { ActionAlert } from '../types/ActionAlert';
import { ActionAlertType } from '../types/ActionAlert';
import { Field, FieldValueType, PendingUploadFile, RadioSelectField } from '../types/Field';
import { NameValueDict } from '../types/FormTypes';
import { ApiResponse, ApiResponseStatus, ServerValidationError } from '../util/ApiRequest';
import { ISelectOption } from '../model/helper';
import { action, observable, runInAction } from 'mobx';
import { createViewModel } from 'mobx-utils';

type CustomFormParams = {
  initialFields: NameValueDict<Field>;
  onSubmit: (values: NameValueDict<FieldValueType>) => Promise<ApiResponse>;
  successMessage?: string;
  successCallback?: (response: ApiResponse) => void;
  onCancel?: () => void;
  onFieldChange?: () => void;
};

export type CustomFormReturnValue = {
  actionAlert: ActionAlert | undefined;
  assignNewFields: (newFields: NameValueDict<Field>) => void;
  fields: NameValueDict<Field>;
  handleChange: (field: Field, newValue: any) => boolean;
  handleFormSubmit: (event: React.FormEvent<HTMLFormElement> | undefined) => Promise<void>;
  isLoading: boolean;
  onSubmitFields: () => Promise<ApiResponse>;
  resetForm: () => void;
  setActionAlert: React.Dispatch<React.SetStateAction<ActionAlert | undefined>>;
  validateFields: (fieldNames?: string[] | null, runValidations?: boolean) => boolean;
  formDirty: boolean;
  setFormDirty: (value: boolean) => void;
};

const useCustomForm = ({
  initialFields,
  onSubmit,
  successMessage,
  successCallback,
  onCancel,
  onFieldChange,
}: CustomFormParams) => {
  const [fields, setFields] = useState(createViewModel(observable(initialFields || {})));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [actionAlert, setActionAlert] = useState<ActionAlert>();
  const [formDirty, setFormDirty] = useState(false);

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && onCancel) {
        onCancel();
      } else if (e.key) {
        setFormDirty(true);
      }
    };
    window.addEventListener('keyup', handler);
    return () => {
      window.removeEventListener('keyup', handler);
    };
  }, [onCancel]);

  const validateFields = useCallback(
    (fieldNames: string[] | null = null, runValidations: boolean = true) => {
      let hasErrors = false;

      if (!fieldNames) fieldNames = Object.keys(initialFields);

      fieldNames.forEach((fieldName) => {
        const field = fields[fieldName];
        if (runValidations) {
          field.validate(fields);
        }
        if (field.error) hasErrors = true;
      });
      return hasErrors;
    },
    [fields, initialFields]
  );

  const assignNewFields = (newFields: NameValueDict<Field>) => {
    setFields(createViewModel(observable(newFields)));
  };

  /**
   * Return false if there are validation errors, true otherwise
   */
  const handleChange = action((field: Field, newValue: any) => {
    setActionAlert(undefined);

    // if it's a radio field, the value is an ISelect
    if (field instanceof RadioSelectField) {
      const option: ISelectOption = {
        value: newValue,
        label: 'invalid',
      };
      for (let i = 0; i < field.options.length; i++) {
        const o = field.options[i];
        if (o.value === newValue) {
          option.label = o.label;
        }
      }
      field.value = option;
    } else {
      field.value = newValue;
    }

    onFieldChange?.();

    if (field.validate) {
      field.validate(fields);
    }

    return !field.error;
  });

  const resetForm = () => {
    setIsLoading(false);
  };

  const resetAllFields = () => {
    Object.values(fields).forEach((f) => {
      const val = f.value as unknown;
      if (val instanceof PendingUploadFile) {
        val.close();
      }
    });
    fields.reset();
  };

  const onSubmitFields = async (): Promise<ApiResponse> => {
    if (validateFields()) {
      setActionAlert({
        text: 'Some of the fields contain errors. Please check your selection and retry',
        type: ActionAlertType.Danger,
      });
      return { status: ApiResponseStatus.Error, data: 'Validation errors' };
    }
    setIsLoading(true);
    setActionAlert(undefined);
    const values: NameValueDict<FieldValueType> = {};
    const fieldNames = Object.keys(initialFields);

    for (let i = 0; i < fieldNames.length; i++) {
      const fieldName = fieldNames[i];
      const field = fields[fieldName];
      try {
        // eslint-disable-next-line no-await-in-loop
        const value = await field.getValueOnSubmit();
        if (
          Array.isArray(value) &&
          value.length > 0 &&
          value.every((v: unknown) => typeof v === 'object' && (v as any).objectKey === 'uploaded')
        ) {
          // never submit 'uploaded' values
          // this is an artifact of the file field setup..
          continue;
        }
        values[fieldName] = value;
      } catch (error) {
        setIsLoading(false);
        // Do not submit the form if there are errors getting the values of the fields
        return {
          status: ApiResponseStatus.Error,
          data: 'Some of the fields contain errors. Please check your selection and retry.',
        };
      }
    }
    let response;
    try {
      response = await onSubmit(values);
    } catch (e: any) {
      response = { status: ApiResponseStatus.Error, data: e.message };
    }
    setIsLoading(false);

    // If there is an error with the fields' values, we display that under its respective field.
    if (response.status === ApiResponseStatus.Error && response.payload?.detail instanceof Array) {
      const validationError: ServerValidationError = response.payload;
      let fieldErrorMatched = false;
      runInAction(() => {
        validationError.detail.forEach((error) => {
          let fieldName = error.loc[error.loc.length - 1];
          // In case the error appeared for a sub-field, show it on the field
          if (fieldName.indexOf('[') !== -1)
            fieldName = fieldName.substring(0, fieldName.indexOf('['));
          const field = fields[fieldName];
          if (field) {
            field.error = error.msg;
            fieldErrorMatched = true;
          }
        });
      });
      if (!fieldErrorMatched) {
        setActionAlert({
          text: 'Some of the fields contain errors. Please check your selection and retry',
          type: ActionAlertType.Danger,
        });
      }
    } else if (response.status === ApiResponseStatus.Error) {
      setActionAlert({
        text:
          response.data.detail ||
          response.data.details ||
          response.data ||
          (typeof response.data === 'string' ? response.data : JSON.stringify(response.data)),
        type: ActionAlertType.Danger,
      });
    } else {
      resetAllFields();
      if (successMessage) {
        setActionAlert({ text: successMessage, type: ActionAlertType.Success });
      }
      if (successCallback) {
        successCallback(response);
      }
    }
    return response;
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement> | undefined) => {
    if (event) event.preventDefault();
    await onSubmitFields();
  };

  return {
    fields,
    assignNewFields,
    validateFields,
    actionAlert,
    setActionAlert,
    isLoading,
    handleChange,
    handleFormSubmit,
    onSubmitFields,
    resetForm,
    formDirty,
    setFormDirty,
  } as CustomFormReturnValue;
};

export default useCustomForm;
