import { ISelectOption } from '../model/helper';

/**
 * Caution: this function assumes the timezone of the passed-in Date is the same
 * as the process's TZ environment. i.e. it does not convert between time zones
 * when retrieving the date string.
 * @param date
 */
export const getYMDString = (date?: Date) => {
  if (date) {
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${date.getFullYear()}-${month}-${day}`;
  }
};

export function getLocaleDate(date: Date | string) {
  let timeZone;

  if (typeof date === 'string') {
    if (date.includes('T')) {
      date = new Date(date);
    } else {
      // If `date` does not include a time portion, ensure we don't run into
      // time zone conversion issues
      const [year, month, day] = date.split('-');
      date = new Date(Date.UTC(+year, +month - 1, +day));
      timeZone = 'UTC';
    }
  }

  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone,
  });
}

export const get12HourTimeString = (time: string) => {
  const [hours, minutes] = time.split(':');
  try {
    let hoursNumber = Number.parseInt(hours, 10);
    const ending = hoursNumber >= 12 ? 'PM' : 'AM';
    if (hoursNumber > 12) hoursNumber -= 12;
    return `${hoursNumber}:${minutes} ${ending}`;
  } catch {
    return 'Invalid time';
  }
};

/**
 * @param yearsIntoFuture - The number of years into the future to get the date range for
 * @returns an array of two dates, the first being the current date and the second being
 * the latest date that can be selected, calculated as the current date plus the number
 * of years into the future
 */
export const getSelectableDateRange = (yearsIntoFuture: number) => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();
  const currentDay = today.getDate();
  const endDate = new Date(currentYear + yearsIntoFuture, currentMonth, currentDay);
  return {
    startDate: getYMDString(today),
    endDate: getYMDString(endDate),
  };
};

export const getPageNumber = (value: string) => {
  let pageNumber;
  try {
    pageNumber = parseInt(value || '1', 10);
  } catch {
    pageNumber = 1;
  }
  return pageNumber;
};

/**
 * Scrolls to the top of the page. If the user has not requested reduced motion,
 * the scroll will be smooth. The function also ensures that it is being
 * called in a browser environment.
 * @returns void
 */
export const scrollToTop = () => {
  if (window) {
    const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
    if (!prefersReducedMotion) {
      window.scrollTo({ behavior: 'smooth', top: 0 });
    } else {
      window.scrollTo(0, 0);
    }
  }
};

/**
 * Scrolls `element` into view. If the user has not requested reduced motion,
 * the scroll will be smooth. The function also ensures that it is being
 * called in a browser environment.
 * @param element - The element to scroll into view
 * @returns void
 */
export const scrollElementIntoView = (element: HTMLElement) => {
  if (window) {
    const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
    if (!prefersReducedMotion) {
      element.scrollIntoView({ behavior: 'smooth' });
    } else {
      element.scrollIntoView();
    }
  }
};

/**
 * Array of numbers from start to stop, incremented by step
 * @param {number} start - The starting point of the range
 * @param {number} stop - The ending point of the range
 * @param {number} step - The step increment size
 * @returns an Array of numbers from start to stop, incremented by step
 */
export const range = (start: number, stop: number, step: number): number[] =>
  Array.from({ length: (stop - start) / step + 1 }, (_: any, i: number) => start + i * step);

/**
 * Array of objects with label and value properties for use with a SelectField
 * @param {number} start - The starting point of the range
 * @param {number} stop - The ending point of the range
 * @param {number} step - The step increment size
 * @returns an Array of objects with label and value properties
 */
export const rangeForSelect = (start: number, end: number, step: number): ISelectOption[] => {
  const rangeArray = range(start, end, step);
  return rangeArray.map((value) => ({ value: `${value}`, label: `${value}` }));
};

/**
 * Returns and array of objects with label and value properties for use with a `StandardSelectFormField`
 * @returns US states as an ISelectOption array.
 */
export const getUSStates = (): ISelectOption[] => [
  {
    label: 'Alabama',
    value: 'AL',
  },
  {
    label: 'Alaska',
    value: 'AK',
  },
  {
    label: 'Arizona',
    value: 'AZ',
  },
  {
    label: 'Arkansas',
    value: 'AR',
  },
  {
    label: 'California',
    value: 'CA',
  },
  {
    label: 'Colorado',
    value: 'CO',
  },
  {
    label: 'Connecticut',
    value: 'CT',
  },
  {
    label: 'Delaware',
    value: 'DE',
  },
  {
    label: 'District Of Columbia',
    value: 'DC',
  },
  {
    label: 'Florida',
    value: 'FL',
  },
  {
    label: 'Georgia',
    value: 'GA',
  },
  {
    label: 'Hawaii',
    value: 'HI',
  },
  {
    label: 'Idaho',
    value: 'ID',
  },
  {
    label: 'Illinois',
    value: 'IL',
  },
  {
    label: 'Indiana',
    value: 'IN',
  },
  {
    label: 'Iowa',
    value: 'IA',
  },
  {
    label: 'Kansas',
    value: 'KS',
  },
  {
    label: 'Kentucky',
    value: 'KY',
  },
  {
    label: 'Louisiana',
    value: 'LA',
  },
  {
    label: 'Maine',
    value: 'ME',
  },
  {
    label: 'Maryland',
    value: 'MD',
  },
  {
    label: 'Massachusetts',
    value: 'MA',
  },
  {
    label: 'Michigan',
    value: 'MI',
  },
  {
    label: 'Minnesota',
    value: 'MN',
  },
  {
    label: 'Mississippi',
    value: 'MS',
  },
  {
    label: 'Missouri',
    value: 'MO',
  },
  {
    label: 'Montana',
    value: 'MT',
  },
  {
    label: 'Nebraska',
    value: 'NE',
  },
  {
    label: 'Nevada',
    value: 'NV',
  },
  {
    label: 'New Hampshire',
    value: 'NH',
  },
  {
    label: 'New Jersey',
    value: 'NJ',
  },
  {
    label: 'New Mexico',
    value: 'NM',
  },
  {
    label: 'New York',
    value: 'NY',
  },
  {
    label: 'North Carolina',
    value: 'NC',
  },
  {
    label: 'North Dakota',
    value: 'ND',
  },
  {
    label: 'Ohio',
    value: 'OH',
  },
  {
    label: 'Oklahoma',
    value: 'OK',
  },
  {
    label: 'Oregon',
    value: 'OR',
  },
  {
    label: 'Pennsylvania',
    value: 'PA',
  },
  {
    label: 'Rhode Island',
    value: 'RI',
  },
  {
    label: 'South Carolina',
    value: 'SC',
  },
  {
    label: 'South Dakota',
    value: 'SD',
  },
  {
    label: 'Tennessee',
    value: 'TN',
  },
  {
    label: 'Texas',
    value: 'TX',
  },
  {
    label: 'Utah',
    value: 'UT',
  },
  {
    label: 'Vermont',
    value: 'VT',
  },
  {
    label: 'Virginia',
    value: 'VA',
  },
  {
    label: 'Washington',
    value: 'WA',
  },
  {
    label: 'West Virginia',
    value: 'VW',
  },
  {
    label: 'Wisconsin',
    value: 'WI',
  },
  {
    label: 'Wyoming',
    value: 'WY',
  },
];
