/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import useCustomForm from '../../../../../hooks/useCustomForm';
import { ProposalCreateFields } from '../../../../../util/fields/ProposalCreateFields';
import { UserFields } from '../../../../../util/fields/UserFields';
import { TextLikeField } from '../../../../../types/Field';
import { InputField } from '../../../../atoms/InputField';
import { Checkbox } from '../../../../atoms/Checkbox';
import { AutoCompleteAddress } from '../../../../organisms/AutoCompleteAddress';
import { ApiResponse } from '../../../../../util/ApiRequest';
import { IProposal } from '../../../../../model/Proposal';
import { Proposal } from '../../../../../store/Proposal';
import { useUserStore } from '../../../../../store/user';
import { IUserInfo } from '~/model/UserInfo';

import addressPageStyles from './ProposalAddressPage.module.scss';
import styles from './ProposalPage.module.scss';
import type { ActionAlert } from '~/types/ActionAlert';

type Props = {
  beforeSubmitCallback: (callback: any) => void;
  proposal: Proposal;
  onSubmit: (changedValues: Partial<IProposal>) => Promise<ApiResponse>;
  onFieldChange: () => void;
  submitFieldCallback: (callback: any) => void;
  setActionAlert: (actionAlert: ActionAlert | undefined) => void;
};

export const ProposalAddressPage: React.FC<React.PropsWithChildren<Props>> = observer(
  ({
    beforeSubmitCallback,
    proposal,
    onSubmit: callerSubmit,
    onFieldChange,
    submitFieldCallback,
    setActionAlert,
  }) => {
    const userStore = useUserStore();
    const user = userStore.getUserInfo();
    const userHasValidAddress = userStore.hasValidAddress();
    const managerId = userStore.getManagerId();

    const createFields: ProposalCreateFields = new ProposalCreateFields(proposal);
    const userFields = new UserFields();
    const addressFieldNames = userFields.userAddressFields;
    const allFields = [...addressFieldNames];
    if (managerId) {
      allFields.push('primaryContactId');
    }
    const initialFields = createFields.getFields(allFields);
    const [hasPrimaryContact, setHasPrimaryContact] = useState<boolean>(
      () => !!proposal.primaryContactId
    );

    const clientUserInfo: IUserInfo | null = userStore.getUserInfo();
    const primaryContactOptions = [
      { id: 1, label: 'Select option', value: '' },
      { id: 2, label: `${clientUserInfo?.firstName} ${clientUserInfo?.lastName}`, value: 'false' },
      { id: 3, label: 'You: Patient Manager', value: 'true' },
    ];

    const { fields, handleChange, onSubmitFields, actionAlert } = useCustomForm({
      initialFields,
      onSubmit: (values): Promise<ApiResponse> => {
        proposal.mergeForm(values);
        return callerSubmit(values);
      },
      onFieldChange,
    });

    const [sameAddress, setSameAddress] = useState<boolean>(() => {
      // If the user does not have a valid address saved in their account, they do not
      // have the option to choose that one as the proposal address
      if (!userHasValidAddress) return false;
      let sameAddressSelected = true;
      if (user) {
        addressFieldNames.forEach((n) => {
          // If at least one of the fields are filled in with a custom value,
          // expand the custom address section
          if (fields[n].value && fields[n].value !== user[n]) {
            sameAddressSelected = false;
          }
        });
      }
      return sameAddressSelected;
    });

    // pass custom function to call _before_ submitting the form
    beforeSubmitCallback(() => {
      // need to copy address values
      if (sameAddress) {
        addressFieldNames.forEach((n) => {
          if (fields[n].error) {
            /*
             * If one of the existing fields of the address triggers an error, expand the
             * existing address section or the error message will be hidden.
             * https://app.shortcut.com/accessrose/story/4892/a-validation-error-is-hidden-on-the-home-information-modal
             */
            setSameAddress(false);
          }

          if (user) {
            fields[n].value = user[n];
            handleChange(fields[n], fields[n].value);
          }
        });
      }
    });
    // pass function to call when this step is submitted
    submitFieldCallback(onSubmitFields);
    setActionAlert(actionAlert);

    return (
      <>
        <section>
          <h3>Enter home address information</h3>

          {userHasValidAddress && (
            <div className={addressPageStyles.sameAddressLabel}>
              <label>
                <div>
                  <Checkbox
                    name="same"
                    data-testid="same-address-checkbox"
                    checked={sameAddress}
                    onClick={() => setSameAddress(!sameAddress)}
                  />
                  <span>Same as address on my account</span>
                </div>
              </label>

              {sameAddress && (
                <p className={addressPageStyles.userAddress}>
                  <span>{user?.streetAddress}</span>
                  <br />
                  <span>
                    {user?.city}, {user?.state} {user?.zipCode}
                  </span>
                </p>
              )}
            </div>
          )}

          {!sameAddress && (
            <div>
              <div
                className={
                  !userHasValidAddress ? styles.standardField : addressPageStyles.editAddress
                }
              >
                <AutoCompleteAddress handleChange={handleChange} fields={fields} />
              </div>
            </div>
          )}
        </section>
        {Boolean(managerId) && (
          <section>
            <h3>Contact</h3>
            <div className={addressPageStyles.primaryContact}>
              <label htmlFor="contact-primary-contact">Who should be the primary contact?</label>

              <select
                id="contact-primary-contact"
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  const isPrimaryContact = event.target.value.toLowerCase() === 'true';
                  if (isPrimaryContact) {
                    fields.primaryContactId.value = managerId as string;
                    setHasPrimaryContact(true);
                  } else {
                    fields.primaryContactId.value = '';
                    setHasPrimaryContact(false);
                  }
                }}
                value={String(hasPrimaryContact)}
              >
                {primaryContactOptions.map((option) => (
                  <option key={option.id} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <InputField
                onChange={handleChange}
                field={fields.primaryContactId as TextLikeField}
              />
            </div>
          </section>
        )}
      </>
    );
  }
);

ProposalAddressPage.displayName = 'ProposalAddressPage';
